import React, { useState, useRef } from "react";
import "../../styles/EditableParagraph.scss";
/**
 * Allows the user to edit the provided text and save it.
 * The text area and hancleChange are needed to make sure that
 * the p tag grows with the text that is being entered
 */
const EditableParagraph = ({ children, update, ...props }) => {
    const [text, setText] = useState(children);
    const disableEditing = e => update(e.currentTarget.value, props);
    const taElement = useRef(null);
    const handleChange = e => setText(e.currentTarget.value);
    return (
        <div className="EditableParagraph">
            <p>{text}</p>
            <textarea
                ref={taElement}
                onBlur={disableEditing}
                onChange={handleChange}
                value={text}
            />
        </div>
    );
};

export default EditableParagraph;
