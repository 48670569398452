import React, { useState, useEffect } from "react";
import { languageName } from "../../../config/languages";
import EditableTitle from "../../UI/EditableTitle";

const shouldUpdate = ([a, b]) => a.value + b.value;

const ReviewTitles = ({ titles, updateTitles, doc }) => {
    const [stateTitles, setTitle] = useState(titles);

    const hasUpdatedTitle = Array.isArray(titles) && shouldUpdate(titles);

    const update = (value, returnProps) => {
        returnProps.value = value;
        setTitle(
            titles.map(title =>
                title.lang === returnProps.lang ? returnProps : title
            )
        );
    };

    useEffect(() => {
        const [a, b] = titles;
        const [x, y] = stateTitles;

        if (a.value === x.value && b.value === y.value) return;

        updateTitles(titles, doc._id);
    }, [hasUpdatedTitle, updateTitles, stateTitles, titles, doc._id]);

    return (
        <div className="Review__header-titles">
            {titles.map(item => (
                <div className="Review__header-title" key={item.value}>
                    <label>{languageName(item.lang)} Title</label>
                    <EditableTitle item={item.value} update={update} {...item}>
                        {item.value}
                    </EditableTitle>
                </div>
            ))}
        </div>
    );
};

export default ReviewTitles;
