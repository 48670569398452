import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addParagraph, deleteParagraph } from "../../../actions/editorActions";

export const ParagraphAction = ({
    paragraph,
    addParagraph,
    deleteParagraph,
    doc,
    chapter,
    idx = 0,
    isLegacyBook,
    isLeft
}) => {
    const handleClick = () => addParagraph(doc._id, chapter, paragraph);
    const handleDel = () => {
        const confirmed = window.confirm("Are you sure? It's gone forever...");
        confirmed && deleteParagraph(doc._id, chapter, paragraph);
    };
    return (
        <div className="me-paragraph__action">
            <div className="me-paragraph__action-number">{idx + 1}</div>
            {isLegacyBook ? null : (
                <ActionButtons
                    handleClick={isLeft ? handleClick : handleDel}
                    isLeft={isLeft}
                />
            )}
        </div>
    );
};

ParagraphAction.propTypes = {
    idx: PropTypes.number,
    addParagraph: PropTypes.func.isRequired,
    deleteParagraph: PropTypes.func.isRequired,
    doc: PropTypes.shape({}),
    chapter: PropTypes.shape({})
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ addParagraph, deleteParagraph }, dispatch);

export default connect(null, mapDispatchToProps)(ParagraphAction);

// -------

export const ActionButtons = ({ handleClick, isLeft }) => (
    <button
        className="me-paragraph__action-button"
        onClick={handleClick}
        tabIndex="-1"
    >
        {isLeft ? `+` : `-`}
    </button>
);

ActionButtons.propTypes = {
    handleClick: PropTypes.func.isRequired,
    isLeft: PropTypes.bool
};
