import React from "react";
import { Link } from "react-router-dom";

const HeaderLogo = () => (
    <Link to="/" className="Header_logo">
        <img src="/editoory.svg" alt="editoory logo" />
    </Link>
);

export default HeaderLogo;
