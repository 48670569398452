import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/ChapterList.scss";
import { bindActionCreators } from "redux";
import {
    selectChapter,
    addChapter,
    deleteChapter
} from "../../../actions/editorActions";
import { fetchDoc } from "../../../actions/documentActions";
import ChapterListItem from "./ChapterListItem";

const ChapterList = ({
    selectChapter,
    addChapter,
    chapter = {},
    doc,
    deleteChapter,
    match,
    fetchDoc
}) => {
    useEffect(() => {
        !doc && fetchDoc(match.params.id, true);
    }, [doc, fetchDoc, match.params.id]);

    if (!chapter) return null;
    const chapterNodes = doc.chapters.map((chapterItem, i) => (
        <ChapterListItem
            key={chapterItem.number + i}
            selectChapter={selectChapter}
            deleteChapter={deleteChapter}
            chapterItem={chapterItem}
            chapter={chapter}
            doc={doc}
            chapterIndex={i + 1}
        />
    ));
    const chapterIndex = doc.chapters.findIndex(c => c._id === chapter._id);
    return (
        <div className="ChapterList">
            <div className="ChapterList__action">
                <div>
                    Chapter {chapterIndex + 1} of {doc.chapters.length}
                </div>
                <button onClick={() => addChapter(doc)}>+ Add Chapter</button>
            </div>
            <div className="ChapterList__chapters">{chapterNodes}</div>
        </div>
    );
};

const mapStateToProps = state => ({
    doc: state.documentReducer.doc,
    chapter: state.editorReducer.chapter
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            selectChapter,
            addChapter,
            fetchDoc,
            deleteChapter
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ChapterList);
