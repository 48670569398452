export const authorization = {
    IS_LOGGING_IN: "IS_LOGGING_IN",
    IS_LOGGING_OUT: "IS_LOGGING_OUT",
    CANCEL_LOGIN: "CANCEL_LOGIN",
    PROCESS_LOGIN: "PROCESS_LOGIN",
    PROCESS_LOGOUT: "PROCESS_LOGOUT",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    LAST_ROUTE: "LAST_ROUTE",
    REQUESTING_PASSWORD_RESET: "REQUESTING_PASSWORD_RESET",
    RESET_PASSWORD_REQUESTED: "RESET_PASSWORD_REQUESTED",
    RESET_PASSWORD_AGAIN: "RESET_PASSWORD_AGAIN",
    IS_VALIDATING_HASH: "IS_VALIDATING_HASH",
    PASSWORD_HASH_VALIDATED: "PASSWORD_HASH_VALIDATED",
    IS_CHANGING_PASSWORD: "IS_CHANGING_PASSWORD",
    PASSWORD_CHANGE_COMPLETED: "PASSWORD_CHANGE_COMPLETED"
};

export const signup = {
    IS_SIGNING_UP: "IS_SIGNING_UP",
    SIGNUP_COMPLETE: "SIGNUP_COMPLETE",
    SIGNUP_FAILED: "SIGNUP_FAILED"
};

export const user = {
    IS_FETCHING_USER: "IS_FETCHING_USER",
    USER_FETCH_RESPONSE: "USER_FETCH_RESPONSE",
    UPDATE_USER: "UPDATE_USER",
    IS_UPDATING_USER: "IS_UPDATING_USER",
    ADD_LANGUAGE: "ADD_LANGUAGE",
    UPDATE_LANGUAGE: "UPDATE_LANGUAGE",
    REMOVE_LANGUAGE: "REMOVE_LANGUAGE"
};

export const document = {
    IS_FETCHING_DOCUMENTS: "IS_FETCHING_DOCUMENTS",
    RECEIVED_DOCUMENTS: "RECEIVED_DOCUMENTS",
    IS_FETCHING_DOC: "IS_FETCHING_DOC",
    IS_FETCHING_DOC_STATS: "IS_FETCHING_DOC_STATS",
    RECEIVED_DOC: "RECEIVED_DOC",
    RECEIVED_DOC_STATS: "RECEIVED_DOC_STATS",
    RECEIVED_BOOK_COVER: "RECEIVED_BOOK_COVER",
    IS_CREATING_DOCUMENT: "IS_CREATING_DOCUMENT",
    CREATED_DOCUMENT: "CREATED_DOCUMENT",
    DELETING_BOOK: "DELETING_BOOK",
    BOOK_DELETED: "BOOK_DELETED",
    IS_UPLOADING_COVER: "IS_UPLOADING_COVER",
    IS_DOWNLOADING_COVER: "IS_DOWNLOADING_COVER",
    UPLOADED_COVER: "UPLOADED_COVER",
    UPDATING_DESCRIPTION: "UPDATING_DESCRIPTION",
    DESCRIPTION_UPDATED: "DESCRIPTION_UPDATED",
    UPDATING_TITLES: "UPDATING_TITLES",
    TITLES_UPDATED: "TITLES_UPDATED"
};

export const documentViewer = {
    SAVING_PRICE: "SAVING_PRICE",
    PRICE_SAVED: "PRICE_SAVED",
    UPDATING_STATUS: "UPDATING_STATUS",
    STATUS_UPDATED: "STATUS_UPDATED",
    DELETING_COVER: "DELETING_COVER",
    COVER_DELETED: "COVER_DELETED"
};

export const editor = {
    SELECT_CHAPTER: "SELECT_CHAPTER",
    IS_ADDING_CHAPTER: "IS_ADDING_CHAPTER",
    IS_ADDING_PARAGRAPH: "IS_ADDING_PARAGRAPH",
    DELETING_CHAPTER: "DELETING_CHAPTER",
    ADD_CHAPTER: "ADD_CHAPTER",
    EDIT_PARAGRAPH: "EDIT_PARAGRAPH",
    PARAGRAPH_IS_SAVING: "PARAGRAPH_IS_SAVING",
    PARAGRAPH_SAVED: "PARAGRAPH_SAVED",
    PARAGRAPH_ADDED: "PARAGRAPH_ADDED",
    CHAPTER_DELETED: "CHAPTER_DELETED",
    DELETING_PARAGRAPH: "DELETING_PARAGRAPH"
};

export const HTTP_METHOD = {
    GET: "GET",
    PUT: "PUT",
    POST: "POST",
    DELETE: "DELETE"
};

export default {
    ...authorization,
    ...signup,
    ...document,
    ...documentViewer,
    ...user,
    ...editor
};
