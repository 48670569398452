import React from "react";
import { Link } from "react-router-dom";
import "../../styles/ZeroBooks.scss";

const ZeroBooks = () => (
    <div className="me-zerobooks__container">
        <div className="me-zerobooks">
            <h1>Great! Your account has been created</h1>
            <p>Lets get started with your first bilingual story</p>
            <Link to="/book/new">Create</Link>
        </div>
    </div>
);

export default ZeroBooks;
