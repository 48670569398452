import React from "react";
import "../../../styles/documentItem.scss";
import { languageName } from "../../../config/languages";

const DocumentItemDetails = ({ doc }) => {
    if (!doc) return null;
    const { titles } = doc;
    if (!titles) return null;

    const languages = titles.map(t => (
        <span key={t.lang}>{languageName(t.lang)}</span>
    ));
    const returnVal = titles.map((title, idx) => (
        <div key={title.value + idx}>
            <label className="text">{title.value}</label>
        </div>
    ));
    return (
        <div className="me-document-item__details">
            <label className="languages">{languages}</label>
            <div>{returnVal}</div>
        </div>
    );
};

export default DocumentItemDetails;
