import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDoc, deleteBook } from "../../../actions/documentActions";
import {
    selectChapter,
    addChapter,
    deleteChapter
} from "../../../actions/editorActions";
import { languageName } from "../../../config/languages";
import Paragraphs from "./Paragraphs";
import "../../../styles/Editor.scss";
import Alert from "react-s-alert";

// TODO test these individually
export const isDifferent = (a, b) => a !== b;
export const getIsbn = doc =>
    Array.isArray(doc.isbn) ? doc.isbn[0] : doc.isbn;
export const getParagraphs = chapter =>
    chapter && chapter.paragraphs ? [...chapter.paragraphs] : [];

export const Editor = ({ doc, chapter, fetchDoc, match, history }) => {
    const [conversionRequired, setConversionState] = useState(false);
    useEffect(() => {
        // ensure there is an actual id
        if (!(match.params.id && match.params.id.length)) {
            Alert.error("The selected book does not have a valid ISBN id");
            return history.push("/");
        }

        setConversionState(false);
        if (!doc) {
            fetchDoc(match.params.id, true);
            return; // cannot be on one line bc: useEffect
        }

        // check if the current doc is already in the store
        isDifferent(getIsbn(doc), match.params.id) &&
            fetchDoc(match.params.id, true);
    }, [match.params, history, fetchDoc, doc]);

    if (!doc) return "Loading...";

    let { languages } = doc;
    if (!languages || languages.length < 1)
        languages = [doc.language1, doc.language2];

    return (
        <div className="me-document-edit">
            <ConversionNotice show={conversionRequired} bookId={doc._id} />
            <div className="me-document-edit__languages">
                {languages.map(lang => (
                    <div key={lang}>{languageName(lang)}</div>
                ))}
            </div>
            <Paragraphs
                doc={doc}
                chapter={chapter}
                setConversionState={setConversionState}
            >
                {getParagraphs(chapter)}
            </Paragraphs>
        </div>
    );
};
const mapStateToProps = state => ({
    doc: state.documentReducer.doc,
    chapter: state.editorReducer.chapter,
    isLoading: state.editorReducer.isLoading,
    covers: state.documentReducer.covers
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDoc,
            selectChapter,
            addChapter,
            deleteBook,
            deleteChapter
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Editor);

// -----

export const ConversionNotice = ({ show, bookId }) => {
    if (!show) return null;

    return (
        <a
            className="me-document-edit__notice"
            alt="This book is still is still in an older format and needs to be converted before it can be edited"
            href={`mailto:info@duolir.com?subject=Please convert book: ${bookId}`}
        >
            Click to request document conversion
        </a>
    );
};
