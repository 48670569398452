import React, { useState } from "react";
import "../../styles/NewBook.scss";
import NewBookForm from "./NewBookForm";
import NewBookCheckList from "./NewBookCheckList";
import { languageCode } from "../../config/languages";
import Alert from "react-s-alert";
import Summary from "./Summary";
import { createDocument } from "../../actions/documentActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export const formatBook = unformattedBook => {
    const book = { ...unformattedBook };
    const languageCodes = [
        languageCode(book.language1),
        languageCode(book.language2)
    ];
    if (!(languageCodes && languageCodes.length === 2)) {
        throw new Error(
            "Language provided not in language list. Please contact support"
        );
    }
    book.titles = [
        {
            lang: languageCodes[0],
            value: book.title1
        },
        {
            lang: languageCodes[1],
            value: book.title2
        }
    ];
    book.languages = languageCodes;
    delete book.title1;
    delete book.title2;
    delete book.language1;
    delete book.language2;
    delete book.step;

    if (book.titles.find(t => t.value === "")) {
        throw new Error(
            "Title is missing. Can't publish a book without a title."
        );
    }
    return book;
};

// ---------------------------- New Book ----------------------------
export const NewBook = props => {
    const [step, changeStep] = useState(0);
    const [book, updateBook] = useState({
        language1: "English",
        language2: "French",
        title1: "My awesome title...",
        title2: "My awesome title...",
        step: 0
    });

    const confirmBook = book => {
        try {
            return formatBook(book);
        } catch (e) {
            Alert.error(e.message);
            changeStep(step - 1);
        }
    };

    const edit = e => {
        e.preventDefault();
        changeStep(step - 1);
    };

    const save = book => {
        props.createDocument(book);
        props.history.push("/");
    };

    return (
        <div className="NewBook__container">
            <header>
                <h1>Create a new bilingual book</h1>
            </header>
            <div className={`NewBook ${step === 4 && "summary"}`}>
                {step < 4 && <NewBookCheckList step={step} />}
                {step === 4 ? (
                    <Summary book={confirmBook(book)} edit={edit} save={save} />
                ) : (
                    <NewBookForm
                        {...props}
                        step={step}
                        book={book}
                        changeStep={changeStep}
                        updateBook={updateBook}
                    />
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ createDocument }, dispatch);
export default connect(null, mapDispatchToProps)(NewBook);
