import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUser } from "../../../actions/userActions";
import "../../../styles/UserProfile.scss";
import { NavLink, Switch, Route } from "react-router-dom";

export const UserProfileTab = ({ user, fetchUser }) => {
    useEffect(() => {
        !user && fetchUser();
    }, [user, fetchUser]);

    if (!(user && user.emails)) return <div />;
    const { emails: [email] = [] } = user;
    return (
        <Switch>
            <Route path="/user" component={() => <BackToHome />} />
            <Route
                path="/"
                component={() => <OpenUserSettings email={email.address} />}
            />
        </Switch>
    );
};

UserProfileTab.propTypes = {
    user: PropTypes.shape({
        emails: PropTypes.arrayOf(
            PropTypes.shape({
                address: PropTypes.string
            })
        )
    })
};

const mapStateToProps = state => ({
    user: state.userReducer.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchUser
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileTab);

const OpenUserSettings = ({ email }) => (
    <NavLink to="/user" className="UserProfile-user">
        {email}
    </NavLink>
);

const BackToHome = () => (
    <NavLink to="/" className="UserProfile-close">
        <span className="mdc-icon-button material-icons">close</span>
    </NavLink>
);
