import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const DocumentPrice = ({ doc: { _id, price = 3 }, savePrice }) => {
    const [currentPrice, setPrice] = useState(price);
    useEffect(() => setPrice(price), [price]);

    return (
        <div className="Review__price">
            <span>Price</span>
            <div>
                <input
                    type="number"
                    name="price"
                    title="price"
                    value={currentPrice}
                    onChange={e => setPrice(e.currentTarget.value)}
                    onBlur={e => savePrice(e.currentTarget.value, _id)}
                />
                <label>euro</label>
            </div>
        </div>
    );
};

DocumentPrice.propTypes = {
    doc: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        price: PropTypes.number
    }),
    savePrice: PropTypes.func.isRequired
};

export default DocumentPrice;
