import constants, { HTTP_METHOD } from "../config/constants";
import api from "../config/api";
import { createHeader, firstResponse, logger } from "../config/helpers";
import Alert from "react-s-alert";

export const fetchDocuments = (topic = "featured") => async dispatch => {
    dispatch({
        type: constants.IS_FETCHING_DOCUMENTS
    });

    try {
        const data = await networkFetchDocuments(topic).catch(e =>
            console.error("failed to fetch documents: ", e)
        );
        // check if we did receive data
        if (data && data.status && data.status !== 200) {
            throw new Error("Failed to fetch user collection data");
        }
        data && dispatch({ type: constants.RECEIVED_DOCUMENTS, data });
    } catch (e) {
        console.error(
            "Something went wrong with the user collection request: ",
            e
        );
    }
};

export const networkFetchDocuments = async topic =>
    fetch(api.documents.userCollection, createHeader())
        .then(firstResponse)
        .then(collections => collections);

// ----------

export const fetchDoc = (isbn = "", fullDoc = false) => async dispatch => {
    if (!isbn)
        throw new Error("ISBN not provided for network 'fetchDoc' request");
    dispatch({
        type: constants.IS_FETCHING_DOC,
        data: true
    });

    try {
        const data = await networkFetchDoc(isbn, fullDoc);
        data &&
            dispatch({
                type: constants.RECEIVED_DOC,
                data
            });
    } catch (e) {
        console.error("failed to load document: ", e);
    }
};

const networkFetchDoc = (isbn, fullDoc) => {
    return fetch(api.documents.doc(isbn, fullDoc), createHeader())
        .then(firstResponse)
        .then(doc => doc);
};

// ----- Document stats

export const fetchDocStats = id => async dispatch => {
    dispatch({ type: constants.IS_FETCHING_DOC_STATS });
    try {
        const data = await networkFetchDocStats(id);
        data &&
            dispatch({
                type: constants.RECEIVED_DOC_STATS,
                data
            });
    } catch (e) {
        console.error("Failed to load document stats: ", e);
    }
};

const networkFetchDocStats = isbn =>
    fetch(api.documents.stats(isbn))
        .then(firstResponse)
        .then(stats => stats);

/**
 * Fetches book cover url from remote repository
 * @param {String} coverId
 * @param {String} bookId
 */
export const fetchBookCover = (coverId, bookId, id) => async dispatch => {
    dispatch({
        type: constants.IS_DOWNLOADING_COVER
    });

    try {
        const response = await fetch(api.documents.coverServer(coverId));

        if (!response.ok) {
            throw new Error(`failed to aquire cover data ${response}`);
        }

        const coverUrl = originalImage(await response.json());
        // Verify that the coverUrl was ok and then dispatch it
        coverUrl &&
            dispatch({
                type: constants.RECEIVED_BOOK_COVER,
                data: { bookId, coverUrl, coverId, id }
            });
    } catch (e) {
        console.error(e.message);
    }
};

/**
 * Filters out the original image from
 * a list of image sizes
 * @param {Array} data
 */
export const originalImage = data => {
    if (data && data.sizes) {
        const { size } = data.sizes;
        return size && size.find(image => image.label === "Original").source;
    }
};

export const createDocument = book => async dispatch => {
    dispatch({ type: constants.IS_CREATING_DOCUMENT });

    try {
        const response = await fetch(
            api.documents.createDoc(book),
            createHeader(HTTP_METHOD.POST, book)
        );

        if (!response.ok) {
            throw new Error(`Failed to create new book`);
        }

        dispatch({
            type: constants.CREATED_DOCUMENT,
            data: await response.json()
        });
        Alert.success("Book successfully created");

        // call fetchDocuments which returns a function
        // then call that function with the dispatch object
        fetchDocuments()(dispatch);
    } catch (e) {
        Alert.error(e.message);
    }
};

export const updateTitles = (titles, bookId) => async dispatch => {
    dispatch({
        type: constants.UPDATING_TITLES
    });

    try {
        // don't update if title value is empty
        if (titles.find(({ value }) => value.length < 1)) {
            return;
        }

        const headerData = createHeader(HTTP_METHOD.POST, {
            bookId,
            titles
        });

        const response = await fetch(api.documents.title(bookId), headerData);

        if (!response.ok) {
            throw new Error(`Failed to update titles ${response.status}`);
        }

        dispatch({
            type: constants.TITLES_UPDATED,
            data: titles
        });
    } catch (e) {
        Alert.error(e.message);
    }
};

export const updateDescription = (descriptions, bookId) => async dispatch => {
    dispatch({
        type: constants.UPDATING_DESCRIPTION
    });

    try {
        if (descriptions.value === "") {
            descriptions.value = "Please enter your description here";
        }
        const response = await fetch(
            api.documents.update.description(bookId),
            createHeader(HTTP_METHOD.POST, {
                bookId,
                descriptions
            })
        );

        if (!response.ok) {
            throw new Error(`Failed to update description: ${response.status}`);
        }

        dispatch({
            type: constants.DESCRIPTION_UPDATED,
            data: descriptions
        });
    } catch (e) {
        Alert.error(e.message);
        logger("error", e.message);
    }
};

/**
 * Delete a give book from the user list
 * @param  {String} bookId The bookId of the book that should be deleted
 */
export const deleteBook = bookId => async dispatch => {
    dispatch({
        type: constants.DELETING_BOOK,
        data: bookId
    });

    try {
        const response = await fetch(
            api.documents.delete(bookId),
            createHeader("DELETE")
        );
        response.ok &&
            dispatch({
                type: constants.BOOK_DELETED,
                data: bookId
            });
    } catch (e) {
        Alert.error(`Failed to delete bookId: ${bookId}`);
    }
};
