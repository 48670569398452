import { combineReducers } from "redux";
import userReducer from "./userReducer";
import documentReducer from "./documentReducer";
import editorReducer from "./editorReducer";

export default combineReducers({
    userReducer,
    documentReducer,
    editorReducer
});
