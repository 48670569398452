const {
    REACT_APP_API_TARGET = "https://mytoori-service-acc.herokuapp.com"
} = process.env;
const api = `${REACT_APP_API_TARGET}/api/v2`;

const {
    REACT_APP_FLICKR_KEY: FLICKR_KEY,
    REACT_APP_FLICKR_API_URL: FLICKR_API_URL
} = process.env;

const config = {
    auth: {
        login: `${api}/login`,
        signup: `${api}/signup`
    },
    documents: {
        collection: (collectionName = "featured") =>
            `${api}/collection/${collectionName}`,
        userCollection: `${api}/user-collection`,
        doc: (isbn, fullDoc = false) =>
            fullDoc ? `${api}/document/all/${isbn}` : `${api}/item/${isbn}`,
        createDoc: doc => `${api}/document`,
        delete: id => `${api}/document/${id}`,
        saveParagraph: () => `${api}/document/paragraph`,
        chapter: (chapterNumber = "") =>
            `${api}/document/chapter/${chapterNumber}`,
        paragraph: (paragraphId = "") =>
            `${api}/document/chapter/paragraph/${paragraphId}`,
        stats: isbn => `${api}/item/stats/${isbn}`,
        coverServer: cover =>
            `${FLICKR_API_URL}flickr.photos.getSizes&api_key=${FLICKR_KEY}&photo_id=${cover}&format=json&nojsoncallback=1`,
        cover: (bookId = "") => `${api}/document/cover/${bookId}`,
        title: () => `${api}/document/title`,
        update: {
            description: bookId => `${api}/document/description/${bookId}`,
            price: bookId => `${api}/document/price/${bookId}`,
            status: bookId => `${api}/document/publish/${bookId}`
        }
    },
    user: `${api}/user`
};

export default config;
