import React from "react";

const DocumentStat = ({ children: { value, label } }) => (
    <div className="me-stat">
        <div className="me-stat__value">{value}</div>
        <label className="me-stat__label">{label}</label>
    </div>
);

export default DocumentStat;
