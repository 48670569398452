import constants from "../config/constants";
import jwtDecode from "jwt-decode";

/**
 * Pure login function takes the access token
 * and stores it in local storage
 */
export const login = data => {
    try {
        Boolean(data && data.token) &&
            window.localStorage.setItem(constants.ACCESS_TOKEN, data.token);
    } catch (e) {
        throw new Error("failed to store token in local storage: ", e);
    }
};

/**
 * Checks if there is a JWT token.
 * If there is it's expiry date is checked.
 *
 * window does not exist on the server where the first render
 * is done. Therefore we need to avoid that error on the server
 * by explicitly checking for window.
 */
export const isAuthenticated = () => {
    if (typeof window === "undefined") return;
    const { localStorage } = window;
    if (Boolean(localStorage && localStorage.getItem && localStorage.setItem)) {
        const token = localStorage.getItem(constants.ACCESS_TOKEN);
        if (!token || typeof token !== "string") return false;
        const { exp = 0 } = jwtDecode(token);
        return exp < new Date().getTime();
    }
};
