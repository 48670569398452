import React from "react";
import { Link } from "react-router-dom";

const ReviewHeader = ({ match }) => (
    <div className="ReviewHeader">
        <h1>Review & Publish</h1>
        <Link to={`/edit/${match.params.id}`}>
            <span className="material-icons">close</span>
        </Link>
    </div>
);

export default ReviewHeader;
