import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Review from "./Review";
import {
    fetchDoc,
    fetchDocStats,
    fetchBookCover,
    updateDescription,
    updateTitles
} from "../../../actions/documentActions";
import {
    savePrice,
    publish,
    uploadCover,
    deleteCover
} from "../../../actions/documentViewActions";

const mapStateToProps = state => ({
    isLoading: state.documentReducer.isLoading,
    isLoadingCover: state.documentReducer.isLoadingCover,
    doc: state.documentReducer.doc,
    docStats: state.documentReducer.docStats,
    isFetchingDocStats: state.documentReducer.isFetchingDocStats,
    allCovers: state.documentReducer.covers
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDoc,
            fetchDocStats,
            fetchBookCover,
            uploadCover,
            deleteCover,
            updateDescription,
            updateTitles,
            savePrice,
            publish
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Review);
