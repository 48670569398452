import React from "react";
import "../../../styles/RegisterForm.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { registerUser } from "../../../actions/userActions";
import Alert from "react-s-alert";
import { isAuthenticated } from "../../../auth/auth";
import { Redirect } from "react-router-dom";

export class RegisterForm extends React.Component {
    handleChange = e => {
        this.setState({
            [e.currentTarget.name]: e.currentTarget.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        try {
            const user = this.state;
            if (user.password !== user["password-repeat"]) {
                throw new Error("Passwords don't match");
            }
            delete user["password-repeat"];
            this.props.registerUser(this.state);
        } catch (e) {
            Alert.error(e.message);
        }
    };

    render() {
        if (isAuthenticated()) return <Redirect to="/" />;
        if (this.props.isLoggingIn) return <div>Loading...</div>;
        return (
            <form
                className="Register_form_container"
                onSubmit={this.handleSubmit}
            >
                <label className="Register_label">
                    <div>email</div>
                    <input
                        type="email"
                        name="email"
                        title="enter your email address"
                        onChange={this.handleChange}
                        required
                    />
                </label>
                <label>
                    <div>password</div>
                    <input
                        type="password"
                        name="password"
                        onChange={this.handleChange}
                        required
                        minLength="6"
                    />
                </label>
                <label>
                    <div>password repeat</div>
                    <input
                        type="password"
                        name="password-repeat"
                        onChange={this.handleChange}
                        required
                        minLength="6"
                    />
                </label>
                <div className="Register_form_action_container">
                    <button className="Register_form_action">
                        Create account
                    </button>
                </div>
                <div className="Register_form_action_other">
                    <Link to="/login">Have an account? Login</Link>
                    <Link to="/forgotpassword">Forgot password?</Link>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
    isLoggingIn: state.userReducer.isLoggingIn
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ registerUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
