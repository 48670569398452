import React from "react";
import ReviewTitles from "./ReviewTitles";

const Header = props => (
    <header className="Review__header">
        <ReviewTitles {...props} />
        <HeaderCover {...props} />
    </header>
);

export default Header;

// TODO: this file needs some serious cleanup
const HeaderCover = props => {
    if (props.isLoading || !props.doc) return <div>...loading</div>;
    if (!hasCover(props.covers, props.doc.coverImages))
        return <UploadCover {...props} />;

    return (
        <div className="Review__header-cover">
            <CloudinaryCover {...props} />
            <FlickrCover {...props} />
        </div>
    );
};

// TODO: this can be better
const UploadCover = ({ uploadCover, doc, isLoadingCover }) => {
    const handleUpload = async e => {
        e.preventDefault();
        if (!(e && e.currentTarget && e.currentTarget.files)) return;
        const [bookCover] = e.currentTarget.files;
        const formData = new FormData();
        formData.append(`bookCover`, bookCover);
        formData.append(`isbn`, doc.isbn);
        formData.append(`bookId`, doc._id);
        uploadCover(formData);
    };

    return (
        <form className="Review__header-upload">
            <div className="upload-container">
                {isLoadingCover ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <button>upload cover</button>
                        <input
                            type="file"
                            name="bookCover"
                            accept="image/*"
                            onChange={handleUpload}
                            placeholder="Choose an image to upload"
                        />
                    </>
                )}
            </div>
        </form>
    );
};

const hasCover = (covers, coverImages) =>
    (covers && covers.length > 0) || (coverImages && coverImages.length > 0);

// TODO: this would also return the other images if there are any
// not sure that makes sense to do
const CloudinaryCover = ({ doc = {}, deleteCover }) => {
    if (!(doc.coverImages && doc.coverImages.length > 0)) return null;

    return doc.coverImages.map(({ value }) => {
        value = value.replace(/upload\//, "upload/c_thumb,w_175,h_250,g_face/");
        return (
            <div key={value}>
                <img src={value} alt={value} />
                <DeleteButton action={() => deleteCover(doc)} />
            </div>
        );
    });
};

const FlickrCover = ({ covers, titles, allCovers = [], deleteCover, doc }) => {
    if (!(covers && covers.length > 0)) return null;

    return covers.map(({ value }) => {
        const cover = allCovers.find(cover => cover.coverId === value);
        return cover ? (
            <div>
                <img src={cover.coverUrl} alt={titles[0].value} />
                <DeleteButton action={() => deleteCover(doc, true)} />
            </div>
        ) : null;
    });
};

const DeleteButton = ({ action }) => (
    <button onClick={action} className="mdc-icon-button material-icons">
        delete
    </button>
);
