import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveParagraph, addParagraph } from "../../../actions/editorActions";
import "../../../styles/Editor.scss";

export const newRowValidation = (
    paragraph = {},
    lastParagraph = {},
    beforeLastParagraph = undefined
) => {
    return Boolean(
        paragraph._id &&
            lastParagraph._id &&
            paragraph._id === lastParagraph._id &&
            (!beforeLastParagraph || beforeLastParagraph.text1.length > 0)
    );
};

export const ParagraphEdit = ({
    text,
    paragraph = {},
    name,
    chapter,
    doc,
    language,
    addParagraph,
    saveParagraph
}) => {
    const [stateText, setText] = useState(text);

    const save = e => {
        e.preventDefault();
        const newParagraph = { ...paragraph };
        if (newParagraph[name] !== e.currentTarget.value) {
            newParagraph[name] = e.currentTarget.value;
            saveParagraph(newParagraph);
        }
    };

    /**
     * Adds a new row when the user is at the end of the page
     */
    const shouldAddNewRow = e => {
        e.preventDefault();
        const paragraphs = chapter.paragraphs || [];
        const lastParagraph = paragraphs.pop() || {};
        const beforeLastParagraph = paragraphs[paragraphs.length - 2];
        if (newRowValidation(paragraph, lastParagraph, beforeLastParagraph)) {
            addParagraph(doc._id, chapter, paragraph);
        }
    };

    const editParagraph = e => setText(e.currentTarget.value);

    return (
        <div className="me-paragraph" data-number={paragraph.number}>
            <p className="me-paragraph__view">{text}</p>

            <textarea
                className="me-paragraph__editing"
                placeholder={language}
                value={stateText}
                onChange={editParagraph}
                onBlur={save}
                onFocus={shouldAddNewRow}
                spellCheck={false}
            />
        </div>
    );
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ saveParagraph, addParagraph }, dispatch);
export default connect(null, mapDispatchToProps)(ParagraphEdit);
