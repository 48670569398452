import constants from "../../config/constants";
import { login } from "../../auth/auth";

export let init = {
    isLoggingIn: false,
    user: null,
    token: null,
    languages: []
};

const userReducer = (state = init, { data, type }) => {
    switch (type) {
        case constants.IS_SIGNING_UP:
        case constants.IS_LOGGING_IN: {
            return {
                ...state,
                isLoggingIn: true
            };
        }

        case constants.CANCEL_LOGIN: {
            return {
                ...state,
                isLoggingIn: false
            };
        }

        case constants.PROCESS_LOGIN: {
            login(data);
            return {
                ...state,
                isLoggingIn: false,
                token: data && Object.keys(data).length > 0 ? { ...data } : null
            };
        }

        case constants.UPDATE_USER:
        case constants.USER_FETCH_RESPONSE: {
            return {
                ...state,
                user: { ...data },
                languages: [...data.profile.languages]
            };
        }

        case constants.ADD_LANGUAGE: {
            return {
                ...state,
                languages: [...state.languages, data]
            };
        }

        case constants.UPDATE_LANGUAGE: {
            return {
                ...state,
                languages: state.languages.map(l =>
                    l.dateCreated === (data && data.dateCreated) ? data : l
                )
            };
        }

        case constants.REMOVE_LANGUAGE: {
            return {
                ...state,
                languages: [
                    ...state.languages.filter(
                        ({ dateCreated }) => dateCreated !== data
                    )
                ]
            };
        }

        default: {
            return state;
        }
    }
};

export default userReducer;
