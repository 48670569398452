import constants, { HTTP_METHOD } from "../config/constants";
import api from "../config/api";
import { createHeader } from "../config/helpers";
import Alert from "react-s-alert";

export const savePrice = (price, bookId) => async dispatch => {
    dispatch({
        type: constants.SAVING_PRICE
    });

    try {
        const header = createHeader(HTTP_METHOD.POST, {
            price
        });
        const response = await fetch(
            api.documents.update.price(bookId),
            header
        );
        if (!response.ok) {
            throw new Error(`failed to save price ${response.status}`);
        }
        dispatch({
            type: constants.PRICE_SAVED
        });
    } catch (error) {
        console.error(error.message);
    }
};

export const publish = (bookId, status) => async dispatch => {
    dispatch({
        type: constants.UPDATING_STATUS
    });

    try {
        const response = await fetch(
            api.documents.update.status(bookId),
            createHeader(HTTP_METHOD.PUT, {
                status
            })
        );

        if (!response.ok) {
            throw new Error(`Failed to update status: ${response.status}`);
        }

        dispatch({
            type: constants.STATUS_UPDATED,
            data: {
                bookId,
                status
            }
        });
    } catch (e) {
        console.error(e.message);
    }
};

// TODO: use general header function
export const uploadCover = body => async dispatch => {
    dispatch({
        type: constants.IS_UPLOADING_COVER
    });
    try {
        const response = await fetch(api.documents.cover(), {
            mode: "cors",
            method: "POST",
            headers: {
                authorization: `Bearer ${window.localStorage.getItem(
                    "ACCESS_TOKEN"
                )}`
            },
            body
        });
        if (!response.ok) {
            throw new Error(`Failed to upload: ${response}`);
        }

        dispatch({
            type: constants.UPLOADED_COVER,
            data: await response.json()
        });
    } catch (e) {
        console.error(e.message);
    }
};

export const deleteCover = doc => async dispatch => {
    dispatch({
        type: constants.DELETING_COVER
    });

    try {
        const response = await fetch(
            api.documents.cover(doc._id),
            createHeader(HTTP_METHOD.DELETE, {
                isbn: doc.isbn
            })
        );

        if (!response.ok) {
            throw new Error(`Failed to delete cover ${response.status}`);
        }

        dispatch({
            type: constants.COVER_DELETED
        });
    } catch (e) {
        console.error(e.message);
        Alert.error(e.message);
    }
};
