import React from "react";
import Alert from "react-s-alert";
import "../../../styles/ChapterList.scss";

const getClassName = (currChapterId, selectedChapterId) =>
    `ChapterList__item${
        currChapterId === selectedChapterId ? " selected-chapter" : ""
    }`;

const ChapterListItem = ({
    chapterItem,
    selectChapter,
    deleteChapter,
    chapter,
    doc,
    chapterIndex
}) => {
    const handleDeleteChapter = e => {
        e.preventDefault();
        if (doc.chapters.length <= 1) {
            return Alert.info("Add a new chapter before deleting the last one");
        }

        if (
            !window.confirm(
                "Are you sure? All the data in this chapter will be lost!"
            )
        )
            return;

        const [firstChapter, secondChapter] = doc.chapters;
        chapterItem === firstChapter
            ? selectChapter(secondChapter)
            : selectChapter(firstChapter);
        deleteChapter(doc, chapterItem, chapterIndex);
    };
    return (
        <div className={getClassName(chapterItem._id, chapter._id)}>
            <div
                className="ChapterItem-content"
                onClick={() => selectChapter(chapterItem)}
            >
                {chapterIndex}
            </div>
            <div className="ChapterItem-action">
                <div>Chapter</div>

                <button onClick={handleDeleteChapter}>
                    <span className="mdc-icon-button material-icons">
                        delete
                    </span>
                </button>
            </div>
        </div>
    );
};

export default ChapterListItem;
