import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import reducer from "./reducer/index";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

/**
 * Only activate the dev tools in non-production instance
 * @param {Boolean} isProd
 */
const getMiddleware = isProd =>
    isProd
        ? applyMiddleware(thunkMiddleware)
        : composeWithDevTools(applyMiddleware(thunkMiddleware, logger));

const isProd = Boolean(process.env.NODE_ENV === "production");
const store = createStore(reducer, undefined, getMiddleware(isProd));

export default store;
