import React from "react";
import "./styles/app.scss";
import Header from "./components/Header/Header";
import DocumentList from "./components/DocumentList/DocumentList";
import ReviewContainer from "./components/Document/DocumentView/ReviewContainer";
import Editor from "./components/Document/DocumentEdit/Editor";
import ChapterList from "./components/Document/ChapterList/ChapterList";
import PrivateRoute from "./components/User/PrivateRoute";
import { Switch, Route } from "react-router-dom";
import NotFound from "./components/NotFound/NotFound";
import NewBook from "./components/NewUser/NewBook";
import UserProfilePage from "./components/User/UserProfile/UserProfilePage";

import "../src/styles/default.scss";

const App = () => (
    <div className="me-app__container">
        <Route component={Header} />
        <div className="Main__container">
            <Switch>
                <PrivateRoute path="/view/:id?" component={ReviewContainer} />
                <PrivateRoute
                    path="/edit/chapters/:id"
                    component={ChapterList}
                />
                <PrivateRoute path="/edit/:id?" component={Editor} />
                <PrivateRoute path="/book/new" component={NewBook} />
                <PrivateRoute path="/user" component={UserProfilePage} />
                <PrivateRoute path="/:filterId?" component={DocumentList} />
                <Route component={NotFound} />
            </Switch>
        </div>
    </div>
);

export default App;
