import React from "react";
import PropTypes from "prop-types";
import { languageName } from "../../config/languages";

const Summary = ({ book, edit, save }) => (
    <div className="NewBookSummary">
        <div className="NewBookSummary__cover">
            <div>?</div>
        </div>
        <div className="NewBookSummary__content">
            <label className="NewBookSummary__languages">
                {book &&
                    Array.isArray(book.titles) &&
                    book.titles.map(({ lang }, i) => (
                        <span key={lang + i}>{languageName(lang)}</span>
                    ))}
            </label>
            <label>
                {book &&
                    Array.isArray(book.titles) &&
                    book.titles.map(({ lang, value }, i) => (
                        <div key={lang + value + i}>{value}</div>
                    ))}
            </label>
        </div>
        <div className="NewBookSummary__action">
            <button onClick={edit}>edit</button>
            <button onClick={() => save(book)} autoFocus>
                save
            </button>
        </div>
    </div>
);

Summary.propTypes = {
    book: PropTypes.shape(),
    save: PropTypes.func.isRequired
};

export default Summary;
