import React from "react";

const DocumentItemCover = ({ cover }) => (
    <div className={`me-document-item__image-container ${!cover && "empty"}`}>
        <FormatCover cover={cover} />
    </div>
);

export default DocumentItemCover;

export const FormatCover = ({ cover }) =>
    cover ? (
        <img
            src={cover.value ? getCloudinaryUrl(cover.value) : cover.coverUrl}
            alt={cover.bookId || ""}
            className="me-document-item__image"
        />
    ) : (
        <CoverPlaceHolder />
    );

export const getCloudinaryUrl = url =>
    url.replace(/upload\//, "upload/w_175,h_250,c_fit/");

export const CoverPlaceHolder = () => <div className="me-cover__placeholder" />;
