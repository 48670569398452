import constants, { HTTP_METHOD } from "../config/constants";
import { createHeader, firstResponse } from "../config/helpers";
import api from "../config/api";
import Alert from "react-s-alert";

export const selectChapter = chapter => dispatch => {
    dispatch({
        type: constants.SELECT_CHAPTER,
        data: chapter
    });
};

export const addChapter = (doc, chapterNumber = null) => async dispatch => {
    dispatch({
        type: constants.IS_ADDING_CHAPTER
    });

    try {
        const body = {
            id: doc._id
        };
        const response = await fetch(
            api.documents.chapter(chapterNumber),
            createHeader(HTTP_METHOD.POST, body)
        );

        response &&
            response.ok &&
            dispatch({
                type: constants.ADD_CHAPTER,
                data: await response.json()
            });
    } catch (e) {
        Alert.error(`failed to add chapter ${e}`);
    }
};

export const deleteChapter = (doc, chapter, chapterIndex) => async dispatch => {
    dispatch({
        type: constants.DELETING_CHAPTER
    });

    try {
        const header = createHeader(HTTP_METHOD.DELETE, {
            id: doc._id
        });
        const response = await fetch(
            api.documents.chapter(chapter._id),
            header
        );
        if (!response.ok) {
            throw new Error(`Failed to delete chapter: ${response.status}`);
        }

        dispatch({
            type: constants.CHAPTER_DELETED,
            data: chapter
        });
        const extraMessage =
            (doc.chapters.length > chapterIndex &&
                `Chapter ${chapterIndex +
                    1} has been moved down to chapter ${chapterIndex}`) ||
            "";
        Alert.info(`Chapter ${chapterIndex} deleted. ${extraMessage}`, {
            timeout: 5000
        });
    } catch (error) {
        Alert.error(error.message);
    }
};

export const addParagraph = (
    documentId,
    chapter,
    paragraph
) => async dispatch => {
    dispatch({
        type: constants.IS_ADDING_PARAGRAPH
    });

    try {
        const { _id: chapterId } = chapter;
        const { _id: paragraphId } = paragraph;
        const body = {
            documentId,
            chapterId,
            paragraphId
        };
        const response = await fetch(
            api.documents.paragraph(),
            createHeader(HTTP_METHOD.PUT, body)
        );
        if (!response.ok) {
            throw new Error(
                "Network failure while adding new paragraph",
                response
            );
        }

        response &&
            response.ok &&
            dispatch({
                type: constants.PARAGRAPH_ADDED,
                data: await response.json()
            });
    } catch (e) {
        Alert.error(
            `Failed to add paragraph. Chapter number: ${
                chapter.number
            } Error: ${e.message}`
        );
    }
};

export const deleteParagraph = (
    bookId,
    chapter,
    paragraph
) => async dispatch => {
    dispatch({ type: constants.DELETING_PARAGRAPH });

    try {
        const response = await fetch(
            api.documents.paragraph(paragraph._id),
            createHeader(HTTP_METHOD.DELETE, {
                id: bookId,
                chapter,
                paragraph
            })
        );

        if (!response.ok) {
            throw new Error(`failed to delete paragraph ${response.status}`);
        }

        dispatch({
            type: constants.RECEIVED_DOC,
            data: { doc: await response.json() }
        });
    } catch (e) {
        console.error(e.message);
    }
};

export const editParagraph = paragraph => dispatch => {
    dispatch({
        type: constants.EDIT_PARAGRAPH,
        data: paragraph
    });
};

export const saveParagraph = paragraph => async dispatch => {
    // dispatch({
    //     type: constants.PARAGRAPH_IS_SAVING,
    //     data: paragraph
    // });

    try {
        const data = await networkSaveParagraph(paragraph);

        dispatch({
            type: constants.PARAGRAPH_SAVED,
            data
        });
    } catch (e) {
        const message = "failed to update paragraph";
        Alert.error(message);
        console.error(message, e);
    }
};

/**
 * Is there an endpoint that will allow a paragraph to be updated? I might need to create that first.
 * @param {*} paragraph
 */
export const networkSaveParagraph = paragraph =>
    fetch(
        api.documents.saveParagraph(),
        createHeader(HTTP_METHOD.POST, { paragraph })
    )
        .then(firstResponse)
        .then(data => data)
        .catch(e =>
            console.error("failed to update the paragraph of document\n", e)
        );
