import React from "react";

const steps = [
    "Set first language",
    "Title for first language",
    "Set second language",
    "Title for second language"
];

const NewBookCheckList = ({ step }) => (
    <article className={`NewBook-checklist ${step}`}>
        <dl>
            {steps.map((stepValue, i) => (
                <dt
                    key={stepValue}
                    className={`step-${
                        step === i ? "selected" : step > i ? "done" : ""
                    }`}
                >
                    {stepValue}
                </dt>
            ))}
        </dl>
    </article>
);

export default NewBookCheckList;
