import React, { useEffect } from "react";
import DocumentStat from "./DocumentStat";
import "../../../styles/documentStat.scss";

const DocumentStats = ({
    items = [],
    isFetchingDocStats,
    fetchDocStats,
    id
}) => {
    useEffect(() => {
        fetchDocStats(id);
    }, [id, fetchDocStats]);

    return (
        <div className="Review__stats-list">
            {!items || isFetchingDocStats ? (
                <div>Loading stats...</div>
            ) : (
                items.map((item, i) => (
                    <DocumentStat key={item + i}>{item}</DocumentStat>
                ))
            )}
        </div>
    );
};

export default DocumentStats;
