import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addParagraph } from "../../../actions/editorActions";
import Alert from "react-s-alert";

export const NoParagraph = ({ doc = {}, chapter = {}, addParagraph }) => {
    const handleClick = () => {
        if (!(doc && doc._id)) {
            Alert.warning("Failed to add chapter. Invalid document");
            return;
        }
        addParagraph(doc.doc._id, chapter);
    };
    return (
        <div>
            No paragraphs in chapter: {chapter.number}
            <div>
                <button onClick={handleClick} alt="Create a new paragraph">
                    + Paragraph
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ addParagraph }, dispatch);

export default connect(null, mapDispatchToProps)(NoParagraph);
