import React, { useEffect } from "react";
import DocumentItem from "../Document/DocumentItem/DocumentItem";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDocuments, fetchBookCover } from "../../actions/documentActions";
import ZeroBooks from "../NewUser/ZeroBooks";
import Navbar from "./Navbar";
import "../../styles/documentList.scss";

const getVisibleList = (filterId, documentList) => {
    if (!filterId) return documentList;
    return documentList.filter(item =>
        !item.status
            ? /draft/i.test(filterId)
            : RegExp(item.status).test(filterId)
    );
};

const DocumentList = ({
    documentList = [],
    fetchDocuments,
    isLoading,
    ...props
}) => {
    useEffect(() => {
        fetchDocuments();
    }, [documentList.length, fetchDocuments]);

    if (isLoading) return <div>loading...</div>;
    if (!isLoading && documentList.length < 1) return <ZeroBooks />;

    const visibleList = getVisibleList(
        props.match.params.filterId,
        documentList
    );
    return (
        <>
            <Navbar {...props} />
            <div className="DocumentList_container">
                <div className="DocumentList">
                    {visibleList.map((doc, idx) => (
                        <DocumentItem
                            key={doc.isbn.join() + idx}
                            doc={doc}
                            {...props}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    documentList: state.documentReducer.documentList,
    covers: state.documentReducer.covers,
    isLoading: state.documentReducer.isLoading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDocuments,
            fetchBookCover
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);
