import constants from "../../config/constants";

const init = {
    documentList: [],
    isLoading: true,
    isLoadingCover: false,
    doc: null,
    docStats: null,
    covers: [],
    isLegacyBook: false
};

/**
 * Loops through the book contents and finds the paragraph that is being edited
 * This allows the front-end to stay up date while the backend is saving the changes.
 * I think this is known as optimistic updating or something like that.
 * TODO: this code can be cleaner and the text etc. should be arrays. Not a single property.
 * @param {Object} updatedDoc The document that is being updated
 * @param {Object} updatedParagraph The paragraph that has been updated
 */
const findAndUpdateParagraph = (updatedDoc, updatedParagraph) => {
    const updatedChapter = updatedDoc.doc.chapters.find(
        c => c._id === updatedParagraph.chapterid
    );
    if (!updatedChapter) return updatedDoc;
    const paragraph = updatedChapter.paragraphs.find(
        p => p.id === updatedParagraph.id
    );
    if (paragraph) {
        paragraph.text1 = updatedParagraph.text1;
        paragraph.text2 = updatedParagraph.text2;
    } else if (updatedParagraph.prevParagraph) {
        // const prevParagraphIndex = updatedChapter.paragraphs.findIndex( p => p._id === updatedParagraph.prevParagraph);

        /* find the paragraph that needs to be moved down the list
         * to make room for this new one that will be inserted
         * Once found, update it's `prevParagraph` to the one being inserted
         */
        const nextParagraphIndex = updatedChapter.paragraphs.findIndex(
            p => p.prevParagraph === updatedParagraph.prevParagraph
        );
        if (nextParagraphIndex > -1)
            updatedChapter.paragraphs[nextParagraphIndex].prevParagraph =
                updatedParagraph._id;
        nextParagraphIndex === -1
            ? updatedChapter.paragraphs.push(updatedParagraph)
            : updatedChapter.paragraphs.splice(
                  nextParagraphIndex,
                  0,
                  updatedParagraph
              );
    } else {
        updatedChapter.paragraphs.push(updatedParagraph);
    }

    return updatedDoc;
};

const documentReducer = (state = init, { data, type }) => {
    switch (type) {
        case constants.IS_FETCHING_DOCUMENTS:
        case constants.IS_CREATING_DOCUMENT:
        case constants.IS_ADDING_CHAPTER:
        case constants.UPDATING_STATUS:
        case constants.DELETING_CHAPTER:
            return {
                ...state,
                isLoading: true
            };

        case constants.DELETING_COVER:
        case constants.IS_UPLOADING_COVER: {
            return {
                ...state,
                isLoadingCover: true
            };
        }

        case constants.RECEIVED_DOCUMENTS: {
            return {
                ...state,
                isLoading: false,
                documentList: [...data.books]
            };
        }
        case constants.IS_FETCHING_DOC: {
            return {
                ...state,
                isLoading: true,
                isFetchingDoc: data,
                doc: null
            };
        }

        case constants.RECEIVED_DOC_STATS: {
            return {
                ...state,
                isLoading: false,
                docStats: data
            };
        }
        case constants.RECEIVED_DOC: {
            const doc = data.doc ? { ...data.doc } : { ...data };
            return {
                ...state,
                isLoading: false,
                doc
            };
        }
        case constants.ADD_CHAPTER: {
            const doc = { ...state.doc };
            const chapters = [...doc.chapters, data];
            doc.chapters = chapters;
            return {
                ...state,
                isLoading: false,
                doc
            };
        }

        case constants.CHAPTER_DELETED: {
            const chapters = state.doc.chapters.filter(c => c._id !== data._id);

            const documentList = state.documentList.map(docItem => {
                if (docItem._id === state.doc._id) {
                    docItem.chapters = chapters;
                }
                return docItem;
            });
            return {
                ...state,
                isLoading: false,
                doc: {
                    ...state.doc,
                    chapters
                },
                documentList
            };
        }

        case constants.PARAGRAPH_IS_SAVING: {
            return {
                ...state,
                doc: findAndUpdateParagraph({ ...state.doc }, data)
            };
        }

        case constants.PARAGRAPH_ADDED:
        case constants.PARAGRAPH_SAVED: {
            return {
                ...state,
                doc: { ...data }
            };
        }

        // TODO this can be refactored with a simple "map" or "some" function
        case constants.RECEIVED_BOOK_COVER: {
            const newCover = { ...data };
            const covers = [...state.covers]; // copy so it can be modified
            const coverIndex = covers.findIndex(
                item => item.bookId === newCover.bookId
            );

            // if the cover has been found then replace
            // otherwise add it to the array
            coverIndex > -1
                ? covers.splice(coverIndex, 1, newCover)
                : covers.push(newCover);

            return {
                ...state,
                isLoadingCover: false,
                covers
            };
        }

        case constants.UPLOADED_COVER: {
            const documentList = state.documentList.map(doc => {
                if (doc._id === state.doc._id) {
                    return {
                        ...doc,
                        ...data
                    };
                }
                return doc;
            });
            return {
                ...state,
                isLoadingCover: false,
                documentList,
                doc: {
                    ...state.doc,
                    ...data
                }
            };
        }

        case constants.STATUS_UPDATED: {
            const updatedDocumentList = state.documentList.map(item => {
                if (item._id === data.bookId) {
                    item.status = data.status;
                }
                return item;
            });
            return {
                ...state,
                isLoading: false,
                doc: {
                    ...state.doc,
                    status: data.status
                },
                documentList: updatedDocumentList
            };
        }

        case constants.COVER_DELETED: {
            return {
                ...state,
                isLoadingCover: false,
                doc: {
                    ...state.doc,
                    coverImages: null
                }
            };
        }

        default: {
            return state;
        }
    }
};

export default documentReducer;
