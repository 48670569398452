import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../styles/loginForm.scss";

export const LoginForm = ({ login, isLoggingIn }) => {
    const [user, setUser] = useState({ email: "", password: "" });

    const handleChange = event =>
        setUser({
            ...user,
            [event.currentTarget.name]: event.currentTarget.value
        });

    const handleLogin = event => {
        event.preventDefault();
        login(user);
    };

    if (isLoggingIn) return <div>loading...</div>;
    return (
        <form className="Login_form_container" onSubmit={handleLogin}>
            <label className="Login_label">
                <div>email</div>
                <input
                    type="email"
                    name="email"
                    alt="enter email adress"
                    title="Email address used during sign up"
                    value={user.email}
                    onChange={handleChange}
                    required
                />
            </label>
            <label className="Login_label">
                <div>password</div>
                <input
                    type="password"
                    name="password"
                    alt="enter password"
                    title="Password set during signup"
                    value={user.password}
                    onChange={handleChange}
                    required
                />
            </label>
            <div className="Login_form_action_container">
                <button className="Login_primary_action">Login</button>
            </div>
            <div className="Login_form_action_other">
                <Link to="/register">Signup</Link>
                <Link to="/forgotpassword">Forgot password</Link>
            </div>
        </form>
    );
};

export default LoginForm;
