import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import EditableParagraph from "../../UI/EditableParagraph";
import { languageName } from "../../../config/languages";

const Descriptions = ({ title = "", updateDescription, doc = {} }) => {
    const [descriptions, setDescriptions] = useState(doc.descriptions);

    const update = (value, returnProps) => {
        returnProps.value = value;
        setDescriptions(
            descriptions.map(item =>
                item.lang === returnProps.lang ? returnProps : item
            )
        );
    };

    // update the backend when description changes
    useEffect(() => {
        if (!Array.isArray(doc.descriptions)) return;

        const [x, y] = descriptions;
        const [a, b] = doc.descriptions;
        if (x.value === a.value && y.value === b.value) return;

        updateDescription(descriptions, doc._id);
    }, [updateDescription, descriptions, doc.descriptions, doc._id]);

    return (
        <div className="Review__descriptions">
            <label>
                {Array.isArray(descriptions) &&
                    descriptions.map(({ lang, _id }) => (
                        <span key={_id ? _id : lang}>
                            {languageName(lang)} {title}
                        </span>
                    ))}
            </label>
            <div>
                {Array.isArray(descriptions) &&
                    descriptions.map(item => (
                        <EditableParagraph
                            key={item._id ? item._id : item.lang}
                            update={update}
                            {...item}
                        >
                            {item.value}
                        </EditableParagraph>
                    ))}
            </div>
        </div>
    );
};

Descriptions.propTypes = {
    doc: PropTypes.shape(),
    updateDescription: PropTypes.func.isRequired
};

export default Descriptions;
