import React, { useEffect } from "react";
import DocumentItemCover from "../DocumentItem/DocumentItemCover";
import "../../../styles/TitleEdit.scss";
import { getCover } from "../../../config/helpers";

const MainTitle = ({
    doc: { cover, id, coverImages, titles },
    covers,
    fetchBookCover,
    match
}) => {
    // TODO: this code can be faster. Update the helper (it's used in 2 places only)
    const [firstCover] =
        getCover({ coverImages, id }, covers, match.params.id) || [];

    useEffect(() => {
        if (coverImages && coverImages.length > 0) return;

        // try to get the cover images from Flickr if the cloudinary ones are not there
        const downloadedCover = covers.find(item => item.id === id);
        !downloadedCover && fetchBookCover(cover, match.params.id, id);
    });

    return (
        <div className="TitleEdit--title-section">
            <DocumentItemCover cover={firstCover} small={true} />
            <div className="TitleEdit--title">
                <div>
                    {titles.map(({ lang = "", value = "" }, idx) => (
                        <label key={value + idx} className="book__title-text">
                            {lang.toUpperCase()} - {value}
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MainTitle;
