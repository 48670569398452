import React from "react";
import "../../../styles/documentItem.scss";

const DocumentItemStatus = ({ doc: { status = "draft", price = 0 } }) => (
    <div className="me-document-item__action">
        <div>
            <label>Price</label>
            <label>€{price}</label>
        </div>
        <div className={`me-document-item__action-status ${status}`}>
            {status}
        </div>
    </div>
);

export default DocumentItemStatus;
