import React from "react";
import PropTypes from "prop-types";
import { Link, Route } from "react-router-dom";
import "../../../styles/TitleEdit.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchBookCover } from "../../../actions/documentActions";
import { selectChapter, addChapter } from "../../../actions/editorActions";
import ChapterTitle from "./ChapterTitle";
import MainTitle from "./MainTitle";

// TODO: cleanup code
export const TitleEdit = ({
    match,
    covers = [],
    fetchBookCover,
    chapter,
    selectChapter,
    addChapter,
    doc
}) => {
    if (!(doc && doc._id)) return null;

    const firstIsbn = typeof doc.isbn === "string" ? doc.isbn : doc.isbn[0];

    return (
        <div className="TitleEdit">
            <MainTitle
                doc={doc}
                covers={covers}
                fetchBookCover={fetchBookCover}
                match={match}
            />
            <div className="TitleEdit--title-section">
                <Route
                    render={props =>
                        /\/edit|\/view/.test(props.match.path) && (
                            <ChapterTitle
                                {...props}
                                doc={doc}
                                selectChapter={selectChapter}
                                addChapter={addChapter}
                                chapter={chapter}
                            />
                        )
                    }
                />
                <Route
                    exact
                    path="/edit/:bookId"
                    render={() => (
                        <Link
                            to={`/view/${firstIsbn}`}
                            className="TitleEdit--action-review"
                        >
                            Review & Publish
                        </Link>
                    )}
                />
                <Link to="/">
                    <span className="material-icons">close</span>
                </Link>
            </div>
        </div>
    );
};

TitleEdit.propTypes = {
    doc: PropTypes.shape({
        _id: PropTypes.string.isRequired
    }),
    match: PropTypes.shape().isRequired,
    fetchBookCover: PropTypes.func
};

const mapStateToProps = state => ({
    doc: state.documentReducer.doc,
    covers: state.documentReducer.covers,
    chapter: state.editorReducer.chapter
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchBookCover,
            selectChapter,
            addChapter
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TitleEdit);
