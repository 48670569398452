import React from "react";
import { connect } from "react-redux";
import "../../styles/Dashboard.scss";


const Dashboard = ({ documentList = [] }) => {
    const items = [{
        label: "books",
        value: documentList.length
    }, {
        label: "published",
        value: documentList.filter(doc => doc.status === "published").length
    }, {
        label: "earned",
        value: `€${0}`
    }]
    return (
        <ul className="Dashboard">
            {items.map(item => (
                <li key={item.label}>
                    <div>{item.value}</div>
                    <label>{item.label}</label>
                </li>))}
        </ul>
    );
}

const mapStateToProps = state => ({
    documentList: state.documentReducer.documentList
})

export default connect(mapStateToProps, null)(Dashboard);