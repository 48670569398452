import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { Router, Route, Switch } from "react-router-dom";
import Access from "./components/User/Access";
import RemoteAccess from "./components/User/RemoteAccess";
import history from "./history";
import { Provider } from "react-redux";
import store from "./store/store";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

const AppContainer = () => (
    <Router history={history}>
        <Provider store={store}>
            <Switch>
                <Route path="/login" component={Access} />
                <Route path="/token/:key" component={RemoteAccess} />
                <Route path="/signup" component={Access} />
                <Route path="/" component={App} />
            </Switch>
            <Alert stack={{ limit: 3 }} />
        </Provider>
    </Router>
);

ReactDOM.render(<AppContainer />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
