import React from "react";
import "../../../styles/UserProfilePage.scss";
import LanguageCard from "../../UI/LanguageCard";
import { isoLanguages } from "../../../config/languages";

const LanguagesInProfile = ({ languages = [], addLanguage, ...props }) => {
    const handleClick = () => {
        const unusedIsoLang = isoLanguages.find(
            isoLang =>
                !languages.map(l => l.languageCode).includes(isoLang.code)
        );

        addLanguage(
            {
                dateCreated: Date.now(),
                languageCode: unusedIsoLang.code,
                proficiency: 3
            },
            props.user
        );
    };
    return (
        <div className="Languages">
            <header>
                <label>Languages</label>
            </header>
            <div className="Languages__list">
                {languages.map(lang => (
                    <LanguageCard
                        key={lang.dateCreated + lang.languageCode}
                        {...props}
                    >
                        {lang}
                    </LanguageCard>
                ))}
            </div>
            <div className="Languages__action">
                <button onClick={handleClick} title="add language">
                    <span className="mdc-icon-button material-icons">add</span>
                    Add Language
                </button>
            </div>
        </div>
    );
};

export default LanguagesInProfile;
