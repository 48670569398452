import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";

const ChapterTitle = ({
    selectChapter,
    doc,
    chapter = {},
    location,
    addChapter
}) => {
    useEffect(() => {
        if (!(doc && Array.isArray(doc.chapters))) return;
        if (doc.chapters.length < 1) {
            addChapter(doc);
            return;
        }

        if (chapter) return;

        if (location && location.state) selectChapter(location.state);
        else {
            const [firstChapter] = doc.chapters ? doc.chapters : [];
            firstChapter && selectChapter(firstChapter);
        }
    }, [chapter, doc, selectChapter, location, addChapter]);

    if (!(doc && doc.chapters && chapter)) return null;

    return (
        <div className="TitleEdit--chapters">
            <div className="TitleEdit--chapters-number">
                <label>Chapter</label>
                <ChapterTitleNumber chapters={doc.chapters} chapter={chapter} />
            </div>
            <ChapterTitleContent doc={doc} />
        </div>
    );
};

ChapterTitle.propTypes = {
    doc: PropTypes.shape({
        chapters: PropTypes.array.isRequired
    }).isRequired,
    addChapter: PropTypes.func.isRequired,
    selectChapter: PropTypes.func,
    chapter: PropTypes.shape()
};

ChapterTitle.defaultProps = {
    addChapter: () => {}
};

export default ChapterTitle;

export const ChapterTitleNumber = ({ chapters, chapter }) => (
    <span>{chapter && chapters.findIndex(c => c._id === chapter._id) + 1}</span>
);

ChapterTitleNumber.propTypes = {
    chapters: PropTypes.array,
    chapter: PropTypes.shape()
};

export const ChapterTitleContent = ({ doc = {} }) => {
    const firstIsbn =
        Array.isArray(doc.isbn) && doc.isbn.length > 0 ? doc.isbn[0] : doc.isbn;

    return (
        <Switch>
            <Route
                path="/edit/chapters"
                component={() => (
                    <Link
                        to={`/edit/${firstIsbn}`}
                        className="TitleEdit--chapters-action"
                    >
                        Editor
                    </Link>
                )}
            />
            <Route
                path="/edit"
                component={() => (
                    <Link
                        to={`/edit/chapters/${firstIsbn}`}
                        className="TitleEdit--chapters-action"
                    >
                        Chapters
                    </Link>
                )}
            />
        </Switch>
    );
};

ChapterTitleContent.propTypes = {
    doc: PropTypes.shape()
};
