import React from "react";
import UserProfile from "../User/UserProfile/UserProfile";
import Dashboard from "../Dashboard/Dashboard";
import HeaderLogo from "./HeaderLogo";
import ReviewHeader from "./ReviewHeader";
import { Route, Switch } from "react-router-dom";
import TitleEdit from "../Document/DocumentEdit/TitleEdit";
import "../../styles/header.scss";

const Header = props => (
    <div className="Header_container">
        <header className="Header">
            <Switch>
                <Route path="/edit" component={TitleEdit} />
                <Route path="/view/:id" component={ReviewHeader} />
                <Route path="/" component={() => <FirstHeader {...props} />} />
            </Switch>
        </header>
    </div>
);

export default Header;

const FirstHeader = props => (
    <>
        <HeaderLogo />
        <Dashboard />
        <UserProfile {...props} />
    </>
);
