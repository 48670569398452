import React, { useState, useEffect } from "react";
import ParagraphEdit from "./ParagraphEdit";
import NoParagraph from "./NoParagraph";
import ParagraphAction from "./ParagraphAction";
import { languageName } from "../../../config/languages";

const Paragraphs = ({
    children = [],
    chapter = {},
    doc = {},
    setConversionState,
    ...props
}) => {
    const [isLegacyBook, setIsLegacyBook] = useState(false);
    useEffect(() => {
        if (children.length > 2) {
            const [{}, second, third] = children; // eslint-disable-line
            if (!(second.prevParagraph && third.prevParagraph)) {
                setIsLegacyBook(true);
                setConversionState(true);
            }
        }
    }, [children, setConversionState]);
    if (!chapter) return <div>Please select a chapter</div>;
    const { languages = [] } = doc;
    const [primary, secondary] = languages;

    const paragraphs = children.map((paragraph = {}, idx) => (
        <div className="me-paragraph__row" key={paragraph._id + idx}>
            <ParagraphAction
                {...props}
                paragraph={paragraph}
                doc={doc}
                chapter={chapter}
                idx={idx}
                isLegacyBook={isLegacyBook}
                isLeft={true}
            />
            <div className="me-paragraph__divider" />
            <ParagraphEdit
                {...props}
                name="text1"
                language={languageName(primary)}
                text={paragraph.text1}
                paragraph={paragraph}
                doc={doc}
                chapter={chapter}
            />
            <div className="me-paragraph__divider" />

            <ParagraphEdit
                {...props}
                name="text2"
                language={languageName(secondary)}
                text={paragraph.text2}
                paragraph={paragraph}
                doc={doc}
                chapter={chapter}
            />
            <div className="me-paragraph__divider" />
            <ParagraphAction
                {...props}
                paragraph={paragraph}
                doc={doc}
                chapter={chapter}
                idx={idx}
                isLegacyBook={isLegacyBook}
                isLeft={false}
            />
        </div>
    ));

    return (
        <div className="me-paragraph__row-container">
            {chapter && paragraphs && paragraphs.length > 0 ? (
                paragraphs
            ) : (
                <NoParagraph doc={doc} chapter={chapter} />
            )}
        </div>
    );
};

export default Paragraphs;
