import React from "react";
import PropTypes from "prop-types";
import "../../styles/LanguageCard.scss";
import { isoLanguages } from "../../config/languages";

const LanguageCard = ({ children, ...props }) => {
    const updateLanguageCode = e => {
        props.updateLanguage(
            {
                ...children,
                languageCode: e.currentTarget.value
            },
            props.user
        );
    };
    return (
        <div className="LanguageCard">
            <div className="LanguageCard__header">
                <select
                    aria-label="The name of the language"
                    title="The name of the language"
                    value={children.languageCode}
                    onChange={updateLanguageCode}
                >
                    {isoLanguages.map(lang => (
                        <option key={lang.code} value={lang.code}>
                            {lang.name}
                        </option>
                    ))}
                </select>
            </div>
            <LanguageCardLevel {...props} children={children} />
        </div>
    );
};

LanguageCard.propTypes = {
    children: PropTypes.shape({
        languageCode: PropTypes.string
    }),
    updateLanguage: PropTypes.func,
    user: PropTypes.shape()
};

export default LanguageCard;

export const LanguageCardLevel = ({ children, removeLanguage, ...props }) => {
    const handleDelete = () => removeLanguage(children.dateCreated, props.user);
    const updateLanguageProficiency = e => {
        props.updateLanguage(
            {
                ...children,
                proficiency: parseInt(e.currentTarget.value)
            },
            props.user
        );
    };
    return (
        <div className="LanguageCard__options">
            <label>level</label>
            <div className="LanguageCard__options-select">
                <select
                    aria-label="the level of the selected language"
                    title="Level of selected language"
                    value={children.proficiency}
                    onChange={updateLanguageProficiency}
                >
                    {langLevels.map((label, i) => (
                        <option key={label + (i + 1)} value={i + 1}>
                            {label}
                        </option>
                    ))}
                </select>
            </div>
            <button>
                <span
                    className="mdc-icon-button material-icons"
                    onClick={handleDelete}
                >
                    delete
                </span>
            </button>
        </div>
    );
};

const langLevels = [
    "Beginner",
    "Beginner +",
    "Intermediate",
    "Intermediate +",
    "Fluent",
    "Native"
];
