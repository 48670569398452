import React from "react";
import DocumentItemCover from "./DocumentItemCover";
import "../../../styles/documentItem.scss";
import { getCover } from "../../../config/helpers";
import Alert from "react-s-alert";
import DocumentItemDetails from "./DocumentItemDetails";
import DocumentItemStatus from "./DocumentItemStatus";

class DocumentItem extends React.Component {
    componentDidMount() {
        const { fetchBookCover, doc = {} } = this.props;

        if (!Object.keys(doc).length > 0) return;
        const { covers = [], isbn = [], coverImages = [], id } = doc;
        // The null check is still required because the backend data
        // might override the defaults above
        if (
            (coverImages && coverImages.length > 0) ||
            (covers && covers.length < 1)
        )
            return;
        const [firstCover] = covers;

        fetchBookCover(firstCover.value, isbn[0], id);
    }

    // TODO: find the book based on id
    handleClick = () => {
        const { history, doc } = this.props;
        if (!(doc && doc.isbn && Array.isArray(doc.isbn))) {
            Alert.error("Invalid document");
            return;
        }
        const [firstIsbn] = doc.isbn;
        if (!firstIsbn) {
            Alert.error("The selected book does not have a valid ISBN id");
            return;
        }
        history.push(`/edit/${firstIsbn}`);
    };

    render() {
        const { doc, covers = [] } = this.props;
        const [firstCover] = getCover(doc, covers);
        return (
            <div
                className="me-document-item__container"
                onClick={this.handleClick}
            >
                <div className="me-document-item">
                    <DocumentItemCover cover={firstCover} />

                    <DocumentItemDetails doc={doc} />
                    <DocumentItemStatus doc={doc} />
                </div>
            </div>
        );
    }
}

export default DocumentItem;
