import constants, { HTTP_METHOD } from "./constants";

const httpMethodsWithBody = [
    HTTP_METHOD.POST,
    HTTP_METHOD.PUT,
    HTTP_METHOD.DELETE
];

/**
 * Takes a HTTP request type and creates the header
 * Inserts the user's token in the header so content specifically
 * for this user is accessed.
 * If the create header is called, it means that the code is already
 * running on the client
 * @param {String} requestType The type of HTTP request to perform
 */
export const createHeader = (method = HTTP_METHOD.GET, body = {}) => {
    const returnObject = {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem(
                constants.ACCESS_TOKEN
            )}`
        },
        json: true
    };

    if (httpMethodsWithBody.includes(method.toUpperCase())) {
        returnObject.body = JSON.stringify(body);
    }

    return returnObject;
};

/**
 * The standard first response given by fetch
 * This structure is reused for various fetch calls
 */
export const firstResponse = rsp => {
    if (rsp && rsp.ok && rsp.json && rsp.json instanceof Function)
        return rsp.json();
    else {
        throw new Error("request failed: ", rsp);
    }
};

export const isEmailValid = email => {
    const regExp = new RegExp(
        "^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(.[a-zA-Z](-?[a-zA-Z0-9])*)+$",
        "i"
    );
    const isValid = regExp.test(email);
    if (!isValid) {
        throw new Error("Please enter a valid email address");
    }
    return isValid;
};

export const getCover = (doc, covers = []) => {
    if (!doc) return [];

    if (doc.coverImages && doc.coverImages.length > 0) {
        return doc.coverImages;
    }

    if (covers.length > 0) {
        const cover = covers.find(item => item.id === doc.id);
        return cover && cover.id ? [cover] : [];
    }

    return [];
};

export const logger = (level, message) => {
    if (
        !message ||
        process.env.NODE_ENV === "production" ||
        typeof window === "undefined"
    )
        return null;
    if (!level || !["log", "error", "info"].includes(level)) level = "log";
    return (
        console &&
        console[level] &&
        typeof console[level] === "function" &&
        console[level]({ level: level, message: message }) // eslint-disable-line
    );
};
