import React from "react";
import LoginForm from "./LoginForm/LoginForm";
import RegisterForm from "./RegisterForm/RegisterForm";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login } from "../../actions/userActions";
import { isAuthenticated } from "../../auth/auth";

import "../../styles/AccessPage.scss";

const Access = props => {
    if (isAuthenticated()) return <Redirect to="/" />;
    return (
        <article className="AccessPage">
          <section>
            <Switch>
                <Route
                    path="/login"
                    render={() => <LoginForm {...props} />}
                />
                <Route path="/signup" component={RegisterForm} />
            </Switch>
            </section>
        </article>
    );
};

const mapStateToProps = state => ({
    isLoggingIn: state.userReducer.isLoggingIn,
    user: state.userReducer.user
});

const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Access);
