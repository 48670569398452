import constants from "../../config/constants";

const init = {
    chapter: null,
    paragraph: {
        text1: "",
        text2: ""
    },
    isLoading: false
};

const editorReducer = (state = init, { data, type }) => {
    switch (type) {
        case constants.IS_ADDING_CHAPTER:
            return {
                ...state,
                isLoading: true
            };
        case constants.ADD_CHAPTER:
            const chapter = { ...data };
            chapter.paragraphs = paragraphSort(chapter.paragraphs);
            return {
                ...state,
                isLoading: false,
                chapter: { ...chapter }
            };
        case constants.SELECT_CHAPTER: {
            const chapter = { ...data };
            chapter.paragraphs = paragraphSort(chapter.paragraphs);
            return {
                ...state,
                chapter: { ...chapter }
            };
        }
        case constants.EDIT_PARAGRAPH: {
            const chapter = { ...state.chapter };
            const index = chapter.paragraphs.findIndex(p => p._id === data._id);
            chapter.paragraphs[index] = { ...data };

            return {
                ...state,
                chapter: { ...chapter }
            };
        }

        case constants.PARAGRAPH_SAVED:
        case constants.PARAGRAPH_ADDED: {
            const currentChapterId = state.chapter._id;
            const chapters = [...data.chapters];
            const chapter = chapters.find(
                ({ _id }) => _id === currentChapterId
            );
            // sort paragraphs
            chapter.paragraphs = [...paragraphSort(chapter.paragraphs)];
            return {
                ...state,
                chapter: { ...chapter }
            };
        }

        case constants.RECEIVED_DOC: {
            if (state.chapter && data && data.doc && data.doc.chapters) {
                const chapter = data.doc.chapters.find(
                    ({ _id }) => _id === state.chapter._id
                );
                if (!chapter) return state;
                chapter.paragraphs = paragraphSort(chapter.paragraphs);
                return {
                    ...state,
                    chapter: { ...chapter }
                };
            }

            return state;
        }

        case constants.IS_FETCHING_DOC: {
            return {
                ...state,
                chapter: null,
                paragraph: {
                    text1: "",
                    text2: ""
                },
                isLoading: true
            };
        }

        default: {
            return state;
        }
    }
};

export default editorReducer;

/**
 * Sorts the list of paragraphs. Each paragraph has a
 * previous paragraph so they know after who they are in the list
 * The first doesn't have a previous paragraph value.
 * That one is added first and then the loop is started
 * to find the next paragraph in line.
 * @param {Array} paragraphs The list of paragraphs to sort
 */
const paragraphSort = (existingList = []) => {
    let newList = [existingList[existingList.findIndex(p => !p.prevParagraph)]];
    while (newList.length !== existingList.length) {
        const lastItem = newList[newList.length - 1];
        if (!lastItem) return existingList.sort((a, b) => a.number - b.number);
        const nextItem = existingList.find(
            p => p.prevParagraph === lastItem._id
        );
        newList.push(nextItem);
    }

    return newList;
};
