import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../../../styles/Review.scss";
import Header from "./ReviewHeader";
import DocumentStats from "./DocumentStats";
import Descriptions from "./Descriptions";
import DocumentPrice from "./DocumentPrice";
import Alert from "react-s-alert";

const PUBLISHED = "published";
const DRAFT = "draft";

const Review = ({
    isLoading,
    doc,
    publish,
    docStats = [],
    fetchDoc,
    ...props
}) => {
    useEffect(() => {
        typeof fetchDoc === "function" && fetchDoc(props.match.params.id);
    }, [props.match.params.id, fetchDoc]);

    const handlePublish = () => {
        if (doc.status !== PUBLISHED) {
            if (!(doc.coverImages && doc.coverImages.length > 0)) {
                Alert.warning("Please add a cover image before publishing");
                return;
            }
        }

        const status = doc.status === PUBLISHED ? DRAFT : PUBLISHED;
        publish(doc._id, status);
    };

    if (isLoading || !doc) return "Loading...";
    return (
        <div className="Review-container">
            <div className="Review">
                <Header
                    {...props}
                    isLoading={isLoading}
                    isLoadingCover={props.isLoadingCover}
                    doc={doc}
                    titles={doc.titles}
                    covers={doc.covers}
                />
                <DocumentStats
                    items={docStats}
                    fetchDocStats={props.fetchDocStats}
                    id={doc._id}
                    isFetchingDocStats={props.isFetchingDocStats}
                />

                <Descriptions
                    {...props}
                    doc={doc}
                    updateDescription={props.updateDescription}
                    title="description"
                />

                <div className="Review__price-container">
                    <DocumentPrice doc={doc} savePrice={props.savePrice} />
                    <button
                        onClick={handlePublish}
                        className={
                            doc.status === PUBLISHED ? "isPublished" : ""
                        }
                    >
                        {doc.status === PUBLISHED ? "un-publish" : "publish"}
                    </button>
                </div>
            </div>
        </div>
    );
};

Review.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        })
    }),
    doc: PropTypes.shape(),
    fetchDoc: PropTypes.func.isRequired,
    publish: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default Review;
