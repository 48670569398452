import api from "../config/api";
import constants, { HTTP_METHOD } from "../config/constants";
import { createHeader } from "../config/helpers";
import Alert from "react-s-alert";

/**
 * Log the user into the application
 * @param {Object} credentials
 */
export const login = credentials => async dispatch => {
    dispatch({ type: constants.IS_LOGGING_IN });
    try {
        const header = createHeader(HTTP_METHOD.POST, credentials);
        header.mode = "cors";
        const response = await fetch(api.auth.login, header);

        if (response.status === 401) {
            throw new Error(`Username - Password combination invalid`);
        }

        if (!response.ok) {
            throw new Error(`Failed to login: ${response.status}`);
        }
        dispatch({
            type: constants.PROCESS_LOGIN,
            data: await response.json()
        });
    } catch (e) {
        Alert.error(e.message);
        dispatch({
            type: constants.CANCEL_LOGIN
        });
    }
};

/**
 * Fetch the user details.
 * Used when the application is reloaded to ensure
 * up to date user values
 */
export const fetchUser = () => async dispatch => {
    dispatch({
        type: constants.IS_FETCHING_USER
    });

    try {
        const response = await fetch(api.user, createHeader());

        if (response.status === 401) {
            throw new Error(`Please login`);
        }

        if (!response.ok) {
            throw new Error(`Failed to login: ${response.status}`);
        }
        dispatch({
            type: constants.USER_FETCH_RESPONSE,
            data: await response.json()
        });
    } catch (e) {
        console.info(e.message);
    }
};

/**
 * Creates a new user for the applicaiton
 * @param {Object} credentials The credentials of the new user
 */
export const registerUser = credentials => async dispatch => {
    dispatch({
        type: constants.IS_SIGNING_UP
    });

    try {
        const response = await fetch(
            api.auth.signup,
            createHeader(HTTP_METHOD.POST, credentials)
        );
        if (response.status === 403) {
            throw new Error("Email address already in use");
        }

        response.ok &&
            dispatch({
                type: constants.PROCESS_LOGIN,
                data: await response.json()
            });
    } catch (e) {
        Alert.error(e.message);
        dispatch({
            type: constants.CANCEL_LOGIN
        });
    }
};

export const updateUser = user => async dispatch => {
    dispatch({
        type: constants.IS_UPDATING_USER,
        data: user
    });
    try {
        const response = await fetch(
            api.user,
            createHeader(HTTP_METHOD.PUT, user)
        );
        if (!response.ok) {
            throw new Error(`Failed to update user: ${response.status}`);
        }
        dispatch({
            type: constants.UPDATE_USER,
            data: user
        });
        Alert.success("User saved");
    } catch (e) {
        Alert.error(e.message);
    }
};

export const updateLanguage = (updatedLanguage, user) => async dispatch => {
    dispatch({
        type: constants.UPDATE_LANGUAGE,
        data: updatedLanguage
    });

    if (user) {
        const newLanguages = user.profile.languages.map(l =>
            l.dateCreated === updatedLanguage.dateCreated ? updatedLanguage : l
        );

        const newUser = {
            ...user,
            profile: { ...user.profile, languages: [...newLanguages] }
        };
        const updatedUser = await networkUpdateUser(newUser);

        dispatch({
            type: constants.UPDATE_USER,
            data: updatedUser
        });
    }
};

export const addLanguage = (language, user) => async dispatch => {
    dispatch({
        type: constants.ADD_LANGUAGE,
        data: language
    });

    if (user) {
        const newUser = {
            ...user,
            profile: {
                ...user.profile,
                languages: [...user.profile.languages, language]
            }
        };

        const updatedUser = await networkUpdateUser(newUser);

        dispatch({
            type: constants.UPDATE_USER,
            data: updatedUser
        });
    }
};

export const removeLanguage = (id, user) => async dispatch => {
    dispatch({
        type: constants.REMOVE_LANGUAGE,
        data: id
    });

    if (user) {
        const languages = user.profile.languages.filter(
            ({ dateCreated }) => dateCreated !== id
        );
        const updatedUser = await networkUpdateUser({
            ...user,
            profile: { ...user.profile, languages }
        });

        dispatch({
            type: constants.UPDATE_USER,
            data: updatedUser
        });
    }
};

// ---

const networkUpdateUser = async user => {
    try {
        const response = await fetch(
            api.user,
            createHeader(HTTP_METHOD.PUT, user)
        );
        if (!response.ok) {
            throw new Error(`Failed to update user: ${response.status}`);
        }
        Alert.success("User saved");
        return user;
    } catch (e) {
        Alert.error(e.message);
    }
};
