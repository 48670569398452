import React from "react";
import PropTypes from "prop-types";
import "../../styles/NewBook.scss";
import { isoLanguages, languageCode } from "../../config/languages";
import Alert from "react-s-alert";
import { Link } from "react-router-dom";

export const validateBook = book => {
    if (!(book && Object.values(book).length)) return false;
    for (const p in book) {
        const invalidBook = book[p] === "";
        if (invalidBook) {
            Alert.error(`Please enter a valid value for ${p}`);
            return false;
        }

        if (/language/i.test(p)) {
            const languageCodeFound = languageCode(book[p]);
            if (!languageCodeFound) {
                Alert.error(`Please enter a valid language name for ${p}`);
                return false;
            }
        }
    }
    return true;
};

validateBook.propTypes = {
    book: PropTypes.shape().isRequired
};

export class NewBookForm extends React.Component {
    handleChange = e => {
        e.preventDefault();

        this.props.updateBook({
            ...this.props.book,
            [e.currentTarget.name]: e.currentTarget.value
        });
    };

    nextStep = e => {
        e.preventDefault();
        const { step, changeStep, book } = this.props;
        validateBook(book) && changeStep(step + 1);
    };

    previousStep = e => {
        e.preventDefault();
        const { step, changeStep, book } = this.props;
        validateBook(book) && changeStep(step - 1);
    };

    render() {
        const { step, book } = this.props;
        return (
            <form className="NewBookForm">
                <Link to="/" alt="Cancel book creation">
                    <span className="material-icons">close</span>
                </Link>
                <div className="NewBookForm__content">
                    {step < 4 && getSteps(step, book, this.handleChange)}
                </div>
                <div className="NewBookForm__action">
                    {step > 0 && step < 5 && (
                        <span onClick={this.previousStep} className="btn-back">
                            back
                        </span>
                    )}
                    <button
                        onClick={this.nextStep}
                        className="btn-primary"
                        tabIndex="0"
                    >
                        next
                    </button>
                </div>
            </form>
        );
    }
}

export const getSteps = (
    step,
    { language1, language2, title1, title2 },
    onChange
) => {
    switch (step) {
        case 0:
            return (
                <LanguageStep
                    onChange={onChange}
                    name="language1"
                    value={language1}
                />
            );
        case 1:
            return (
                <TitleStep
                    onChange={onChange}
                    name="title1"
                    language={language1}
                    value={title1}
                />
            );
        case 2:
            return (
                <LanguageStep
                    onChange={onChange}
                    name="language2"
                    value={language2}
                />
            );
        case 3:
            return (
                <TitleStep
                    onChange={onChange}
                    name="title2"
                    language={language2}
                    value={title2}
                />
            );
        default:
            return null;
    }
};

const languageLabels = {
    language1: "Choose first language",
    language2: "Choose second language"
};

export const LanguageStep = ({ onChange, name, value }) => (
    <label>
        <span>{languageLabels[name]}</span>
        <input
            list="languages"
            name={name}
            title={name}
            onChange={onChange}
            value={value}
            required
            autoFocus
            tabIndex="0"
        />
        <datalist id="languages">
            {isoLanguages.map(lang => (
                <option key={lang.code} value={lang.name}>
                    {lang.name}
                </option>
            ))}
        </datalist>
    </label>
);

export const TitleStep = ({ onChange, name, language, value }) => (
    <label>
        <span>
            Title in <span>{language}</span>
        </span>
        <input
            type="text"
            name={name}
            title={name}
            required
            onChange={onChange}
            defaultValue={value}
            autoFocus
            tabIndex="0"
        />
        <small>The title selected now can be updated later</small>
    </label>
);

TitleStep.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
};

export default NewBookForm;
