import React, { useState } from "react";
import md5 from "md5";
import PropTypes from "prop-types";

const UserInfo = ({ user, updateUser }) => {
    const [name, setName] = useState(
        (user && user.profile && user.profile.name) || ""
    );

    const updateName = e => setName(e.currentTarget.value);
    const saveUser = () =>
        updateUser({
            ...user,
            profile: {
                ...user.profile,
                name
            }
        });

    if (user && !user.profile) {
        user.profile = {};
    }

    const [email] = user.emails;
    const hash = email && md5(email.address);
    return (
        <div className="UserInfo">
            <div className="UserInfo-text">
                <label>name</label>
                <input
                    type="text"
                    name="name"
                    title="name"
                    value={name}
                    placeholder="Enter your full name"
                    onChange={updateName}
                    onBlur={saveUser}
                />
                <label>email address</label>
                <div className="email">
                    {user &&
                        user.emails &&
                        user.emails.length > 0 &&
                        user.emails[0].address}
                </div>
            </div>
            <div className="Photo-container">
                <img
                    src={`https://secure.gravatar.com/avatar/${hash}/?s=200`}
                    alt="user profile avatar"
                />
                <div>
                    Create a profile on{" "}
                    <a
                        href="https://gravatar.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Gravatar.com
                    </a>{" "}
                    with the same email address to add profile picture.
                </div>
            </div>
        </div>
    );
};

UserInfo.propTypes = {
    user: PropTypes.shape({
        emails: PropTypes.arrayOf(
            PropTypes.shape({
                address: PropTypes.string.isRequired
            }).isRequired
        ).isRequired
    }).isRequired,
    updateUser: PropTypes.func
};

export default UserInfo;
