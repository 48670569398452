import React, { useEffect } from "react";
import constants from "../../config/constants";
import { Redirect } from "react-router-dom";

/**
 * Used by reader (mytoori.com) to automatically
 * login the user to the editor
 * This avoids the user having to manually login
 * to the editor when they click the link.
 * called with: https://editor.mytoori.com/token/23432jp23ip22p3i4p2afws
 * @param {*} param0
 */
const RemoteAccess = ({ match: { params: { key } = {} } = {} }) => {
    useEffect(() => {
        window && window.localStorage.setItem(constants.ACCESS_TOKEN, key);
    });
    if (key) return <Redirect to="/" />;

    return <div>Auto login failed</div>;
};

export default RemoteAccess;
